@import url(/fonts/Roboto-Black.ttf);
.oi-top-navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 3em;
  background-color: var(--primary-color);
}

.oi-top-navigation-bar-heading {
  color: white;
}

.oi-top-navigation-bar-new-invoice {
  margin-left: 1em !important;
}

.billeroo-top-navigation-bar-buttons {
  display: flex;
}

.billeroo-top-navigation-left {
  display: flex;
  align-items: center;
}

.billeroo-top-navigation-left-logo {
  height: 3em;
  margin-right: 1em;
}

.billeroo-tabs-menu {
  display: none;
  justify-content: space-around;
}

.billeroo-top-navigation-sign-buttons {
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  .billeroo-tabs-menu {
    display: flex;
  }
  .billeroo-tabs-desktop {
    display: none;
  }
}
@media (max-width: 720px) {
  .billeroo-top-navigation-new-invoice-button {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .billeroo-top-navigation-left-logo {
    display: none;
  }
}

:root {
  --primary-color: #2c7f83;
  --secondary-color: #ffa31a;
}

.invoice-app-container {
  margin: 2em 14em;
  min-height: 100vh;
}

.invoice-tab-container {
  margin: 2em 0;
}

body {
  background-color: #f6f6f6;
}

.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label::before {
  background: #b8365588 !important;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #2c7f83; 
  border-top: 16px solid var(--primary-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  margin-top: 1em;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@media (max-width: 1600px) {
  .invoice-app-container {
    margin: 2em 10em;
  }
}

@media (max-width: 1500px) {
  .invoice-app-container {
    margin: 2em 6em;
  }
}

@media (max-width: 1400px) {
  .invoice-app-container {
    margin: 2em 2em;
  }
}

.billeroo-imprint {
  margin-top: 4em;
}

.billeroo-imprint-title {
  color: var(--primary-color);
}

.billeroo-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 2em 3em;
  background-color: var(--primary-color);
  color: white;
  border-top: solid 5px var(--secondary-color);
}

.billeroo-footer-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 3em;
}

.billeroo-footer-column {
  margin: 0 2em 2em;
}

.billeroo-footer-cell > ul {
  list-style-type: none;
  padding: 0;
}

.billeroo-footer-cell a {
  color: white;
}

.billeroo-footer-cell a:hover {
  color: #eff0f1;
}

@media (max-width: 600px) {
  .billeroo-footer-row {
    flex-direction: column;
  }
}

.invoice-form-label-container {
  margin: 1em 0;
}

.input {
  margin-right: 1em;
  margin-bottom: 1em;
}

.invoice-form-buttons {
  margin: 2em 0;
}

.billeroo-invoice-form-selected-article-card {
  padding: 0.5em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25em;
}

.billeroo-invoice-form-service-form {
  padding: 0.5em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.billeroo-invoice-form-service-form:last-child {
  border-bottom: none;
}

.DraftEditor-root {
  font-family: Lato;
}

.billeroo-editor {
  min-height: 10em;
}

.billeroo-service-form-remove-button {
  margin-top: 0.5em !important;
}

.billeroo-service-form-remove-button-wrapper {
  display: flex;
  align-items: center;
}

#singlePage {
  position: relative;
}

.invoice-header-right-textarea {
  width: 100%;
  height: 100%;
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
}

.invoice-header-right-textarea:hover {
  border: 1px solid grey;
}

.invoice-body {
  height: 160mm;
  font-size: 8.5pt;
}

.invoice-body-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4mm;
}

.invoice-body-subject-key-values {
  display: flex;
}

.invoice-body-top-right {
  min-width: 200px;
}

.invoice-body-subject-keys {
  margin-right: 5mm;
}

.invoice-body-subject-values p {
  margin: 0;
}

.invoice-body-price-calculation {
  padding-top: 3mm;
  padding-bottom: 3mm;
  margin-top: 3mm;
  margin-bottom: 3mm;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.invoice-body-price-calculation p {
  margin: 0;
}

.invoice-body-price-calculation
  .invoice-body-price-calculation-label-and-number,
.invoice-body-price {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.invoice-body-price {
  border-bottom: 2px solid black;
  margin-bottom: 3mm;
}

.invoice-body-bottom {
  width: 150mm;
}
.invoice-body-article {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
}

.invoice-body-article-price {
  white-space: nowrap;
}

.invoice-body-article-left {
  font-size: 8pt;
}

br {
  content: "";
  margin: 2em;
  display: block;
  font-size: 0%;
}

.invoice-body-article-description {
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.invoice-folding-line {
  position: absolute;
  top: 104mm;
  left: 4mm;
  width: 5mm;
  border-top: 1px solid black;
}

.invoice-page-image-wrapper {
  display: flex;
  justify-content: flex-end;
}

.invoice-body-article-description > div {
  margin-right: 4px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.invoice-header-company-logo {
  align-self: flex-end;
  right: 0;
  height: 4em;
}

.invoice-header-company-info {
  min-width: 200px;
  height: 100%;
  margin-bottom: 5mm;
  font-size: 8pt;
}

.invoice-page-pdf {
  height: 100%;
  padding: 10mm 12mm 13mm 26mm;
  background: white;
  box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
  font-family: "Roboto", sans-serif;
}

.invoice-page-top {
  display: flex;
  flex-direction: column;
  height: 96mm;
  justify-content: space-between;
}

.invoice-footer {
  padding-top: 10px;
  border-top: 1px solid black;
  font-size: 8.5pt;
}

.invoice-header-customer-company {
  margin-bottom: 2em;
  font-size: 7pt;
}

.invoice-header-customer-info {
  height: 22mm;
  width: 70mm;
  margin-bottom: 19mm;
  font-size: 9pt;
}

.invoice-header-customer-info-name {
  margin-bottom: 0.5em !important;
}

.invoice-subject-and-below {
  margin-bottom: 5mm;
}

.invoice-overlay-wrapper {
  position: relative;
}

.invoice-overlay {
  position: absolute;
  height: 105%;
  width: 105%;
  -webkit-transform: translateX(-2%) translateY(-98%);
          transform: translateX(-2%) translateY(-98%);
}
.invoice-overlay:hover {
  background: #22707488;
  cursor: pointer;
}

.invoice-body-final-text {
  margin-top: 10px;
}

.billeroo-invoice-body-service {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25em;
}

.invoice-body-service-price {
  align-self: flex-end;
  white-space: nowrap;
}

.invoice-page {
  display: flex;
}

.invoice-page-wrapper {
  display: flex;
  flex-direction: column;
}

.invoice-button-wrapper {
  align-self: center;
  margin: 2em;
}

.invoice-page-button-container {
  display: flex;
  justify-content: space-between;
  max-width: 210mm;
  margin: 2em 0;
}

.invoice-page-header {
  padding: 2em;
}

@media (min-width: 1200px) {
  .invoice-page {
    display: flex;
    flex-direction: row;
    padding: 2em;
  }

  .invoice-form-wrapper {
    width: 30%;
    margin: 0 0 0 2.5%;
  }
}

@media (min-width: 1400px) {
  .invoice-form-wrapper {
    width: 35%;
  }
}

@media (min-width: 1600px) {
  .invoice-form-wrapper {
    width: 45%;
  }
}
@media (max-width: 1400px) {
  .invoice-page {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.billeroo-landing-page {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.billeroo-title-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  max-width: 70em;
}

.billeroo-price-section,
.billeroo-reference-section,
.billeroo-added-value-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6em 0;
}

.billeroo-reference-section {
  margin-bottom: 4em;
}

.billeroo-title-section-text {
  max-width: 50em;
  min-width: 20em;
}

.billeroo-price-section {
  width: 100%;
}

.billeroo-price-section-text,
.billeroo-reference-section-text,
.billeroo-added-value-section-text,
.billeroo-title-section-text {
  flex: 1 1;
}

.billeroo-title-section-text > * {
  margin-bottom: 2em;
}

.billeroo-title-section-image {
  flex: 1 1;
  margin-left: 4em;
}

.billeroo-title-section-image > img {
  max-width: 100%;
}

.billeroo-price-section-text-title,
.billeroo-reference-section-text-title,
.billeroo-added-value-section-text-title,
.billeroo-title-section-text-title {
  color: var(--primary-color);
  margin-bottom: 1em !important;
}

.billeroo-reference-section-text-title,
.billeroo-added-value-section-text-title {
  text-align: center;
}

.billeroo-title-section-text-text {
  font-size: 1.2rem;
}

.billeroo-reference-section-images-logo {
  mix-blend-mode: multiply;
  max-height: 3em;
}
.billeroo-added-value-section-values {
  padding: 0em 12% 0em;
}
/*
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: space-around;
  align-items: flex-start;

.billeroo-added-value-section-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 15em;
  margin: 0 2em 4em;
  text-align: center;
}

.billeroo-added-value-section-value-icon-wrapper {
  width: 5em;
}

.billeroo-added-value-section-value-icon-wrapper > svg {
  fill: var(--main-theme-color);
}

.billeroo-landing-page p {
  font-size: 1.2rem;
}

.billeroo-alternate-section {
  background: var(--primary-color);
  color: white;
  border-radius: 2em;
}

.billeroo-alternate-section h1 {
  color: white;
}
*/

.billeroo-added-value-section-value {
  display: flex;
  align-items: center;
  padding: 4em 0;
}

.billeroo-added-value-section-icon-wrapper {
  flex-grow: 1;
  flex-basis: 0;
}

.billeroo-added-value-section-icon-wrapper > img {
  width: 100%;
}

.billeroo-added-value-section-text-wrapper {
  width: 50%;
  flex-grow: 1;
  flex-basis: 0;
}

.billeroo-call-to-action-section {
  margin: 4em 0;
}

.billeroo-blog-section {
  margin: 4em 0;
}

.billeroo-blog-section-title {
  color: var(--primary-color);
  text-align: center;
}
.billeroo-blog-section-entries {
  display: flex;
}

@media (max-width: 623px) {
  .billeroo-title-section {
    flex-direction: column;
  }

  .billeroo-title-section-image {
    margin-left: 0;
    margin-top: 2em;
  }

  .billeroo-added-value-section-values {
    padding-left: 0;
    padding-right: 0;
  }
}

.billeroo-stats-container-title {
  color: var(--primary-color);
  text-align: center;
}

.billeroo-stats-numbers-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 3em 0;
}

.billeroo-stats-numbers-container-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em;
}

.billeroo-stats-container-section-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--secondary-color);
  margin-top: 0.5em;
}

.billeroo-stats-linechart-tooltip {
  background: white;
  border: 1px solid grey;
  padding: 1em;
  border-radius: 0.5em;
}

.billeroo-stats-article-table {
  margin-top: 4em;
}

.billeroo-plan-card-header {
  padding: 2em !important;
}

.billeroo-plan-card-content-item {
  display: flex;
  justify-content: space-between;
}

.billeroo-plan-card-price-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem !important;
}

.billeroo-plan-card-price-month {
  font-size: 0.9rem;
}

.billeroo-price-yearly-toggle-container {
  margin-bottom: 1em;
}

.billeroo-price-yearly-toggle-container .ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label::before {
  background: #22222222 !important;
}

.billeroo-plan-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 6em 0;
}

.billeroo-plan-funnel {
  width: 40em;
  margin: 2em;
}

.billeroo-plan-funnel-header {
  margin-bottom: 1em;
}

.billeroo-plan-funnel-actions {
  display: flex;
  justify-content: flex-end;
}

.billeroo-plan-funnel-billing-address {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.billeroo-plan-funnel-billing-address > div {
  display: flex;
  justify-content: space-between;
}

.billeroo-plan-funnel-step-ack {
  margin-bottom: 1em;
}

.billeroo-plan-funnel-header-step {
  padding: 0.5em;
}

.billeroo-plan-funnel-header-step.done {
  color: var(--primary-color);
}

.billeroo-plan-funnel-header-step.active {
  color: black;
}

.billeroo-plan-funnel-header-step.future {
  color: grey;
}

.billeroo-settings-container {
  display: flex;
  flex-direction: column;
  content: center;
  margin: 2em auto;
  max-width: 50em;
}

.billeroo-user-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.billeroo-current-subscription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0;
}

.billeroo-current-subscription > div {
  margin: 1em 0;
}

.billeroo-cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 3em 2em 2em;
  z-index: 1;
  background: white;
  border-top: 2px solid var(--primary-color);
}

.billeroo-cookie-banner > div {
  padding-right: 2em;
}


.oi-top-navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 3em;
  background-color: var(--primary-color);
}

.oi-top-navigation-bar-heading {
  color: white;
}

.oi-top-navigation-bar-new-invoice {
  margin-left: 1em !important;
}

.billeroo-top-navigation-bar-buttons {
  display: flex;
}

.billeroo-top-navigation-left {
  display: flex;
  align-items: center;
}

.billeroo-top-navigation-left-logo {
  height: 3em;
  margin-right: 1em;
}

.billeroo-tabs-menu {
  display: none;
  justify-content: space-around;
}

.billeroo-top-navigation-sign-buttons {
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  .billeroo-tabs-menu {
    display: flex;
  }
  .billeroo-tabs-desktop {
    display: none;
  }
}
@media (max-width: 720px) {
  .billeroo-top-navigation-new-invoice-button {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .billeroo-top-navigation-left-logo {
    display: none;
  }
}

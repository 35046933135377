.invoice-page {
  display: flex;
}

.invoice-page-wrapper {
  display: flex;
  flex-direction: column;
}

.invoice-button-wrapper {
  align-self: center;
  margin: 2em;
}

.invoice-page-button-container {
  display: flex;
  justify-content: space-between;
  max-width: 210mm;
  margin: 2em 0;
}

.invoice-page-header {
  padding: 2em;
}

@media (min-width: 1200px) {
  .invoice-page {
    display: flex;
    flex-direction: row;
    padding: 2em;
  }

  .invoice-form-wrapper {
    width: 30%;
    margin: 0 0 0 2.5%;
  }
}

@media (min-width: 1400px) {
  .invoice-form-wrapper {
    width: 35%;
  }
}

@media (min-width: 1600px) {
  .invoice-form-wrapper {
    width: 45%;
  }
}
@media (max-width: 1400px) {
  .invoice-page {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.invoice-form-label-container {
  margin: 1em 0;
}

.input {
  margin-right: 1em;
  margin-bottom: 1em;
}

.invoice-form-buttons {
  margin: 2em 0;
}

.billeroo-invoice-form-selected-article-card {
  padding: 0.5em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25em;
}

.billeroo-invoice-form-service-form {
  padding: 0.5em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.billeroo-invoice-form-service-form:last-child {
  border-bottom: none;
}

@import url("/fonts/Roboto-Black.ttf");

#singlePage {
  position: relative;
}

.invoice-header-right-textarea {
  width: 100%;
  height: 100%;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.invoice-header-right-textarea:hover {
  border: 1px solid grey;
}

.invoice-body {
  height: 160mm;
  font-size: 8.5pt;
}

.invoice-body-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4mm;
}

.invoice-body-subject-key-values {
  display: flex;
}

.invoice-body-top-right {
  min-width: 200px;
}

.invoice-body-subject-keys {
  margin-right: 5mm;
}

.invoice-body-subject-values p {
  margin: 0;
}

.invoice-body-price-calculation {
  padding-top: 3mm;
  padding-bottom: 3mm;
  margin-top: 3mm;
  margin-bottom: 3mm;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.invoice-body-price-calculation p {
  margin: 0;
}

.invoice-body-price-calculation
  .invoice-body-price-calculation-label-and-number,
.invoice-body-price {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.invoice-body-price {
  border-bottom: 2px solid black;
  margin-bottom: 3mm;
}

.invoice-body-bottom {
  width: 150mm;
}
.invoice-body-article {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
}

.invoice-body-article-price {
  white-space: nowrap;
}

.invoice-body-article-left {
  font-size: 8pt;
}

br {
  content: "";
  margin: 2em;
  display: block;
  font-size: 0%;
}

.invoice-body-article-description {
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.invoice-folding-line {
  position: absolute;
  top: 104mm;
  left: 4mm;
  width: 5mm;
  border-top: 1px solid black;
}

.invoice-page-image-wrapper {
  display: flex;
  justify-content: flex-end;
}

.invoice-body-article-description > div {
  margin-right: 4px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.invoice-header-company-logo {
  align-self: flex-end;
  right: 0;
  height: 4em;
}

.invoice-header-company-info {
  min-width: 200px;
  height: 100%;
  margin-bottom: 5mm;
  font-size: 8pt;
}

.invoice-page-pdf {
  height: 100%;
  padding: 10mm 12mm 13mm 26mm;
  background: white;
  box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
  font-family: "Roboto", sans-serif;
}

.invoice-page-top {
  display: flex;
  flex-direction: column;
  height: 96mm;
  justify-content: space-between;
}

.invoice-footer {
  padding-top: 10px;
  border-top: 1px solid black;
  font-size: 8.5pt;
}

.invoice-header-customer-company {
  margin-bottom: 2em;
  font-size: 7pt;
}

.invoice-header-customer-info {
  height: 22mm;
  width: 70mm;
  margin-bottom: 19mm;
  font-size: 9pt;
}

.invoice-header-customer-info-name {
  margin-bottom: 0.5em !important;
}

.invoice-subject-and-below {
  margin-bottom: 5mm;
}

.invoice-overlay-wrapper {
  position: relative;
}

.invoice-overlay {
  position: absolute;
  height: 105%;
  width: 105%;
  transform: translateX(-2%) translateY(-98%);
}
.invoice-overlay:hover {
  background: #22707488;
  cursor: pointer;
}

.invoice-body-final-text {
  margin-top: 10px;
}

.billeroo-invoice-body-service {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25em;
}

.invoice-body-service-price {
  align-self: flex-end;
  white-space: nowrap;
}

.billeroo-cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 3em 2em 2em;
  z-index: 1;
  background: white;
  border-top: 2px solid var(--primary-color);
}

.billeroo-cookie-banner > div {
  padding-right: 2em;
}

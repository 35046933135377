:root {
  --primary-color: #2c7f83;
  --secondary-color: #ffa31a;
}

.invoice-app-container {
  margin: 2em 14em;
  min-height: 100vh;
}

.invoice-tab-container {
  margin: 2em 0;
}

body {
  background-color: #f6f6f6;
}

.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label::before {
  background: #b8365588 !important;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid var(--primary-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-top: 1em;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1600px) {
  .invoice-app-container {
    margin: 2em 10em;
  }
}

@media (max-width: 1500px) {
  .invoice-app-container {
    margin: 2em 6em;
  }
}

@media (max-width: 1400px) {
  .invoice-app-container {
    margin: 2em 2em;
  }
}

.billeroo-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 2em 3em;
  background-color: var(--primary-color);
  color: white;
  border-top: solid 5px var(--secondary-color);
}

.billeroo-footer-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 3em;
}

.billeroo-footer-column {
  margin: 0 2em 2em;
}

.billeroo-footer-cell > ul {
  list-style-type: none;
  padding: 0;
}

.billeroo-footer-cell a {
  color: white;
}

.billeroo-footer-cell a:hover {
  color: #eff0f1;
}

@media (max-width: 600px) {
  .billeroo-footer-row {
    flex-direction: column;
  }
}
